import Vue from 'vue';
import gql from 'graphql-tag';
import { DateTime as LuxonDateTime } from 'luxon';

import apollo from '@/services/apollo';
import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';

import './Messages.css';
import Loader from '@/components/Shared/Loader/Loader';

export interface MessageModel {
  created: string;
  email: string;
  id?: string;
  name: string;
  phone: string;
  message: string;
  read: boolean;
  title: string;
}

export interface MessagesData {
  currentMessage: MessageModel | null;
  currentMessageId: string;
  loading: boolean;
  messages: MessageModel[];
}

const MessageSidebarItem = (context: any) => {
  const message = context.props.item as MessageModel;

  return (
    <li class={`sidebar-item message-sidebar-item ${!message.read ? 'read' : ''}`}>
      <router-link
        to={`/admin/messages/${message.id}`}
      >
        <div class="key-value">
          <span class="value">{message.title}</span>

          <span class="key">{LuxonDateTime.fromISO(message.created).toRelative()}</span>
        </div>
      </router-link>
    </li>
  );
};

const Messages = Vue.extend({
  data(): MessagesData {
    return {
      currentMessage: null,
      currentMessageId: '',
      loading: true,
      messages: [],
    };
  },

  methods: {
    changeMessageStatus() {
      const { currentMessage, currentMessageId } = this;

      if (!currentMessage || currentMessage.read) return;

      const index = this.messages.findIndex(message => message.id === currentMessageId);

      if (index === -1) return;

      this.messages[index].read = true;

      const mutation = gql`
        mutation changeMessageStatus(
          $messageId: String!
          $read: Boolean!
        ) {
          changeMessageStatus(
            messageId: $messageId
            read: $read
          ) {
            ok
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          messageId: currentMessageId,
          read: true,
        },
      })
        .catch((err) => {
          console.log('Error changing message => ', err);
        });
    },

    loadCurrentMessage() {
      this.currentMessageId = this.$route.params.messageId;

      if (!this.currentMessageId) {
        this.currentMessage = null;
        return;
      }

      this.currentMessage = this.messages.find(
        message => message.id === this.currentMessageId,
      ) || null;

      this.changeMessageStatus();
    },

    loadMessages() {
      const query = gql`
        {
          getMessages{
            created
            email
            id
            name
            phone
            message
            read
            title
          }
        }
      `;

      apollo.query({
        query,
      })
        .then(({ data }) => {
          this.messages = data?.getMessages || [];
          this.loadCurrentMessage();
        })
        .catch((err) => {
          console.log('Error loading messages => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadMessages();
  },

  name: 'messages',

  render() {
    return (
      <DoublePaneLayout
        filterHandler={null}
        renderSidebarItem={MessageSidebarItem}
        search={null}
        sidebarLoading={this.loading}
        sidebarItems={this.messages}
        sidebarLink={null}
        sortHandler={null}
      >
        {
          this.currentMessage && (
            <header class="dashboard-header">
              <h1 class="dashboard-header__title">{this.currentMessage.title}</h1>
            </header>
          )
        }

        <div class="dashboard-content">
          {
            this.currentMessage
              ? (
                <div>

                  <div class="key-value">
                    <span class="key">Name</span>
                    <span class="value">{this.currentMessage.name}</span>
                  </div>

                  <div class="key-value">
                    <span class="key">Phone</span>
                    <span class="value">{this.currentMessage.phone}</span>
                  </div>

                  <div class="key-value">
                    <span class="key">Email</span>
                    <span class="value">{this.currentMessage.email}</span>
                  </div>

                  <div class="key-value">
                    <span class="key">Message</span>
                    <span class="value">{this.currentMessage.message}</span>
                  </div>

                </div>
              )
              : (
                <div class="generic-empty-state">
                  {
                    this.loading
                      ? <Loader />
                      : <span>No message selected</span>
                  }

                </div>
              )
          }
        </div>

      </DoublePaneLayout>
    );
  },

  watch: {
    $route: 'loadCurrentMessage',
  },
});

export default Messages;
